@media screen and (min-width: 768px) {
  /* .App {
    display: flex;
    flex-direction: row;
  } */

  * {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
  }

  .project_card_container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
}

.App {
  background-color: #f8f9f9;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  color: #3b434c;
}
